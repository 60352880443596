import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/butcher-baker-nightmare-maker-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 horror movie, Butcher, Baker, Nightmare Maker"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 02</h1>
                    <h2>Butcher, Baker, Nightmare Maker</h2>
                    <h3>March 9, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/butcher-baker-nightmare-maker">Back to the episode details</Link></p>
                        <div>
                            <p>Bryan White (00:02.276)
                            recording all right you&#39;re listening to the grim ship now you&#39;re not listen to the grim bro you&#39;re listenin to the yeah let&#39;s take to you&#39;re listening to the brother&#39;s grim podcast presented by the cynimasuicide film society on substack if you want to keep up with us between episodes you can also find us on twitter at grim brows pod and if you like what you hear you can subscribe to us wherever you get your podcasts also you&#39;d be doing us a favor by lit ship now also you&#39;d be doing</p>
                            <p>Bryan White (00:32.316)
                            a favor by leaving us a five star review on apple podcast just wanted to get all that out of the way right up at the top of the show i&#39;m brian white editor in grief of cinema suicide joined by my co host and that brother dave white dave how you do it</p>
                            <p>Bryan White (00:47.536)
                            yeah good good to hear so we practically grew up in neighborhood video stores steady diet of under garbage that those shops provided us with continues unabated to this day and there&#39;s no one else i enjoyed chopping it up with more about trashy movies than dave so this week we we are doing a dose it is it is way more than i was expecting and that is saying something seeing as how last time we did ammidyvill to so</p>
                            <p>Bryan White (01:17.456)
                            i&#39;ll give you give a little taste of what&#39;s coming up here</p>
                            <p>Bryan White (01:31.016)
                            night warning</p>
                            <p>Bryan White (02:14.296)
                            uh uh</p>
                            <p>Bryan White (02:51.616)
                            now</p>
                            <p>Bryan White (02:54.536)
                            so we&#39;re doing night warning also known as butcher baker nightmare maker did you notice fucking terrible terrible names did you notice anything weird about that trailer</p>
                            <p>Bryan White (03:12.496)
                            pretty sure also there&#39;s like one second of susan terrell in the whole thing and she&#39;s basically the star of the movie which is crazy to me</p>
                            <p>Bryan White (03:25.776)
                            it is it is nuts it is just crazy so little let&#39;s do a few little facts a few facts about this thing so also a warning before we get rolling some little feedback about the last episode that people were not expecting we are basically going to talk about this movie from beginning to end so expect spoilers if that&#39;s the thing that&#39;s kind of bugs you which you know doesn&#39;t really bother me but i strongly encourage you</p>
                            <p>Bryan White (03:55.796)
                            pause this go watch the movie it&#39;s like available everywhere you can get it on blue ray you can watch it on shutter it&#39;s it&#39;s very accessible go do that then come back otherwise here we go</p>
                            <p>Bryan White (04:11.196)
                            you&#39;ve had your time so this was released or rather it was produced in nineteen eighty one i guess it was released in eighty two depending on where i looked it always says like this movie was released in eighty one i don&#39;t know so other movies released in eighty one scanners the howling the beyond the evil dead happy birthday to me</p>
                            <p>Bryan White (04:40.056)
                            fun house and an american werewolf in london</p>
                            <p>Bryan White (04:45.096)
                            yeah real fucking battery here too like that&#39;s that&#39;s eighty one is tough to it&#39;s tough to follow i mean that you know you also had halloween to come out that year and friday part two so this was released in the u k as just nightmare maker which i think is a better title but it&#39;s still like i don&#39;t know i don&#39;t know what i don&#39;t know what you were supposed to call this this movie but like originally i had just sort of assumed</p>
                            <p>Bryan White (05:14.716)
                            is no way that the produce the production script of this movie had either night warning or butcher baker nightmare maker on it maybe unless you unless you found something that i didn&#39;t</p>
                            <p>Bryan White (05:30.516)
                            no ship okay</p>
                            <p>Bryan White (05:35.296)
                            all right because one of the things one of the you know just one of the facts that i sort of dug up about this and i can&#39;t really find any any real corroborating evidence of this but i&#39;ve read in a couple of spots that all sort of source the same like i am d b trivial page about it was that this actually began as a novel that was sort of optioned to become a movie and the the original novel was called butcher baker nightmare maker but that might not be true so i don&#39;t know</p>
                            <p>Bryan White (06:12.816)
                            did they did they just novelize everything though because</p>
                            <p>Bryan White (06:20.836)
                            i this jesus christ this is true um like i mean it seems like in like the first few years in the eighties they novelized everything like here&#39;s a friday the thirteenth novelization i think that&#39;s that is crazy but from what i read about it so the novel was actually written by the guys who wrote the movie and the novel expands greatly upon upon the movie and there&#39;s a lot</p>
                            <p>Bryan White (06:50.696)
                            going on in this movie already but like there&#39;s there&#39;s a ton of gaps that you sort of notice if you&#39;re paying attention but like there&#39;s a ton of like background so like there&#39;s lot more back story characters there&#39;s a bit more about coach landers and and brodie the sort of murdered t v repair man and sort of like like their relationship there&#39;s a little bit more about that there&#39;s some stuff about chuck strang the</p>
                            <p>Bryan White (07:21.336)
                            yeah like there&#39;s that there&#39;s a lot more about that on cherell&#39;s um like relationship with him so like this looks like it seems like a book that&#39;s worth kind of digging up because like it&#39;s already pretty pretty interesting um so this was originally directed by a director named michael miller who was one of cormon&#39;s guys but he was canned for moving too slowly which seems really uncharacteristic to me of a roger corman protege</p>
                            <p>Bryan White (07:50.856)
                            it was jean de bont yeah which is wild because like the movies that he directed but the stuff that he was like deep on fuck me that guy worked on like some awesome movies die hard um so he was replaced by with a guy named william h asher who gets the screen credit because i mean he directed the bulk of the movie and if you look him up his like his credit list is really interesting he did mostly television</p>
                            <p>Bryan White (08:20.936)
                            um but he did a bunch of</p>
                            <p>Bryan White (08:25.676)
                            right right so here&#39;s here&#39;s a list of some of some of his titles for features he directed beach party bakin beach muscle beach party beach blanket bingo and how to stuff a wild bickini which is the best title but he was probably known he did a little bit of twilight zone he did</p>
                            <p>Bryan White (08:52.276)
                            all the big ones yeah i love loosely he did some of the later ones the stuff in the fifties um but he was also known for creating bewitched and i don&#39;t know if he was direct if he was if he was married to</p>
                            <p>Bryan White (09:12.556)
                            right because it sort of it sort of strikes me as like a project he was like well i got this girl and i might as well create a thing for her</p>
                            <p>Bryan White (09:32.416)
                            this is true</p>
                            <p>Bryan White (09:36.516)
                            right and this is that&#39;s actually kind of interesting because one of my notes on this is as i was watching about half way through and i pointed out that this movie if it wasn&#39;t for the nudity and like the sort of abundance of gore that happens towards the end this movie has a real like made for tvvibe</p>
                            <p>Bryan White (09:58.976)
                            and like the weird also like the way that he credits roll over the movie at the beginning like the movie is just going it&#39;s not like it has a very like a very long credit sequence it&#39;s like all of a sudden like it&#39;s like guest starting also featuring as like the movie is playing the dialogue and ship happening at the time like just the presentation of it is very strange and it feels very compact and like maybe this was meant for t v at some point so a year after</p>
                            <p>Bryan White (10:28.876)
                            movie was released it basically went completely unnoticed which is his shame and so they re titled it as night warning which i believe is the version that you watched</p>
                            <p>Bryan White (10:44.196)
                            oh right okay so i think i think the code red one has actually butcher baker but anyways it doesn&#39;t even matter because i don&#39;t i think the only difference between the two of them is the title</p>
                            <p>Bryan White (10:55.176)
                            and based on that trailer that we just saw for night warning it&#39;s very clear that they&#39;re trying to down play the susan terrell part for some reason because if there&#39;s any reason to watch this movie holy ship it is her um so so i had an initial thought like as mediately as it starts going because like this movie really does kind of hit the ground running my first thought watching it is it would not take very much</p>
                            <p>Bryan White (11:24.896)
                            to turn this into a john waters movie like</p>
                            <p>Bryan White (11:31.836)
                            cry baby yeah oh</p>
                            <p>Bryan White (11:37.216)
                            the danny elfin movie right because it was mostly it was a vehicle for oingoboingo and the the it starts her i also no ship she was well wait was it then or because i also know that she dated her vi villa chase for a while which is oh no ship i thought you said that she was dating elfin but that&#39;s a fucking strange detail if any if any</p>
                            <p>Bryan White (12:06.396)
                            thing but i also know her she&#39;s in the movie she&#39;s in the first angel movie which i don&#39;t know if you&#39;ve ever seen angel but that movie is sales</p>
                            <p>Bryan White (12:17.976)
                            no no no this is well i don&#39;t know if i call them soft core but it&#39;s the one where it&#39;s like the kind of she&#39;s a high school student by day and a prostitute by night and i&#39;ve i&#39;ve only seen the first one and it&#39;s like there&#39;s a there&#39;s a serial killer who&#39;s like murdering like sex workers on the l a strip and she and like a bunch of like hollywood weirds kind of team up to stop</p>
                            <p>Bryan White (12:47.796)
                            it&#39;s it&#39;s it&#39;s first of all it&#39;s a good movie like you know it&#39;s good by our standards but it is unbelievably sleazy and susan terrell is in it as</p>
                            <p>Bryan White (13:05.156)
                            kind of a maybe like a pimp or like anyway she&#39;s basically yeah and she&#39;s she&#39;s basically it&#39;s her at the end of this movie with the short hair and all that but she&#39;s also like a lesbian</p>
                            <p>Bryan White (13:22.596)
                            but like as i&#39;m watching this i&#39;m thinking like divine could easily replace her boast fencing could be replaced by like tab hunter you know just like there&#39;s there&#39;s it would it wouldn&#39;t take much for this to be to be a john waters flick</p>
                            <p>Bryan White (14:12.616)
                            yeah i&#39;ve got a note</p>
                            <p>Bryan White (14:16.596)
                            i&#39;ve got a note in there and it&#39;s mostly it&#39;s mostly just the way that the way the coach landers himself is portrayed is very is very interesting to me because he&#39;s it&#39;s nineteen eighty two and they very easily could have made him like a limp rested like lisping you know interior decorator or something like that just like the way</p>
                            <p>Bryan White (14:45.416)
                            that that hollywood handled gay characters at the time was just not even a little bit charitable it was always</p>
                            <p>Bryan White (15:09.996)
                            m</p>
                            <p>Bryan White (15:14.096)
                            yeah well i mean it&#39;s got it just in any other movie i think that he would have been like a leather daddy or he would have</p>
                            <p>Bryan White (15:25.656)
                            no yeah yeah i mean it really is a bit of the same sort of revision ism that you tend to see particularly like since nightmare in elm street too is kind of reclaimed over the last couple of years like now i&#39;ve seen robert england talk about it where it&#39;s like it&#39;s very progressive and it&#39;s like i don&#39;t know ask mark patten about that because yeah yeah don&#39;t don&#39;t don&#39;t go watching those</p>
                            <p>Bryan White (15:55.436)
                            search of darkness documentaries then because like the there&#39;s a whole section about it and it&#39;s like that movie kind of ruined mark patten&#39;s entire fucking life you know so like maybe ask him how he feels about how progressive that movie was</p>
                            <p>Bryan White (16:11.996)
                            uh uh</p>
                            <p>Bryan White (16:16.276)
                            yeah i do think i do think that there is i mean and this is just me but i do think that there is really sort of something to be said about this because this is really it just it takes a certain kind of restraint for hollywood not to just turning into into a into a fucking caricature</p>
                            <p>Bryan White (16:43.036)
                            yeah yeah so ship let&#39;s let&#39;s let&#39;s get into this because oh boy um so we start out in the distant past and billy is like three years old i think and he&#39;s being handed off to aunt share as his parents get into their car and then you know we get a we get a sly look from</p>
                            <p>Bryan White (17:10.116)
                            share as they as they take off and then what follows is</p>
                            <p>Bryan White (17:18.696)
                            it&#39;s holy ship it is arch like</p>
                            <p>Bryan White (17:26.696)
                            oh man i could see that because like yeah this would this would have been that kind of that period where she was getting kind of she was getting kind of age and ship because like didn&#39;t she play like she played a funk now i&#39;m the that but i&#39;m trying to think of she was john waters caster mostly for sensational she was the one who was kidnapped and patty hurst yeah yeah</p>
                            <p>Bryan White (17:56.556)
                            oh man but that&#39;s i would i would also like to see that movie because i guarantee like she would also lean the funk in to this</p>
                            <p>Bryan White (18:08.796)
                            uh uh</p>
                            <p>Bryan White (18:15.616)
                            that show is so great so immediately right out the gate they&#39;re driving around what is going on on the back seat of that car there is like a an entire close rack back there um and the first thing that that kind of hits me is that you know the cars we find out that the cars breaks are not working i get the feeling you know and chere knew something about that as it&#39;s sort of revealed later on but</p>
                            <p>Bryan White (18:45.496)
                            car is you know swerving and sliding all over the place and then there is a track like a tractor hauling a bunch of logs and it&#39;s like okay the old logs through the windshield bits coming up</p>
                            <p>Bryan White (19:00.956)
                            this car reck is is really makes sure that billy&#39;s parents are dead it&#39;s like they asked a ten year old to describe their death scene and he just got carried away because the log goes through the wind shield you see that coming a mile away and you know of course you know the head is hit and then it starts to sort of drag the car around the car getting whipped around and then it gets thrown off a cliff it rolls down the cliff it falls off the cliff it hits a river</p>
                            <p>Bryan White (19:30.796)
                            landing on the roof and then the car explodes like it&#39;s so great and like when that happened i&#39;m like all right i see i see where this is going like we&#39;re in for we&#39;re in for a real ride</p>
                            <p>Bryan White (19:51.896)
                            yeah so that&#39;s that&#39;s the end of h of michael miller&#39;s that&#39;s basically his contribution to the whole movie which it does kind of feel like a different movie for real like i mean you know oh ship&#39;s moving too slowly we got to rally pick this pace up like at least he mean he really kind of what what he did deliver was great but so we move up it&#39;s now fourteen years later</p>
                            <p>Bryan White (20:21.916)
                            and he&#39;s been with he&#39;s been living with within a chair all the whole time i believe it&#39;s the day before his birthday and right off the bat we are in weird sexual dynamic land we gotta we gotta we got a do something about this and we&#39;re going to become like the incest pod it is this is this is the second movie where this is kind of a running theme</p>
                            <p>Bryan White (20:50.056)
                            it&#39;s that was so gross so gross</p>
                            <p>Bryan White (21:00.836)
                            it&#39;s super super like it&#39;s very very unpleasant like oh my god like is this is this what they do all the time is this wakes him up like every day how long has she been doing this to him</p>
                            <p>Bryan White (21:22.996)
                            or or more importantly like</p>
                            <p>Bryan White (21:27.176)
                            well no i was going to say like is this reflective of the writers attitudes like is this just like what people fucking did back then and they just threw it in there and then they kind of turned the volume up as the movie goes but i would i would assume that that this supposed to be a red flag like right off the bat that like things are not well in this house</p>
                            <p>Bryan White (21:49.896)
                            ad is that&#39;s crazy like her hair was short originally right like it wud be like the one you see later when she after she&#39;s cut her hair okay yeah so this is you know we&#39;re establishing things and billy is like gonna be seventeen he&#39;s got a girl friend he&#39;s just like you know your average american high school boy at the time he&#39;s played by jimmy mc nichol</p>
                            <p>Bryan White (22:17.716)
                            right right and this was this was kind of like this was really sort of like the high time for that sort of like ten idle time</p>
                            <p>Bryan White (22:31.276)
                            i was i was going to say like it definitely sort of seems like he was probably like hired for that because his sister was also kind of like the kind of plucky kid who who ended up in the you know just the the same sort of situation</p>
                            <p>Bryan White (22:50.896)
                            little darlings</p>
                            <p>Bryan White (22:53.916)
                            yeah but like you know like this was also a time of like leaf garret and shun cassidy and like that stuff was that stuff was really going so i mean i suppose that this was just kind of like you know good vehicle for him and and he does play like the character very well like he&#39;s not he&#39;s not like a one trick pony like these other guys where they you know they kind of like cast them is everything and just sort of like hope that they would float on their good looks because like you know</p>
                            <p>Bryan White (23:24.596)
                            all right but i got i got to look it up i mean a bit you bit they put a mike in front of his face at some point because like all those guys got him but he plays basketball he&#39;s really really good at basketball we&#39;re looking at a we&#39;re looking at a we call it when they let you into college</p>
                            <p>Bryan White (23:45.336)
                            a scholarship that&#39;s the word and all of a sudden a wild bill paxton appears and i love that because he&#39;s great and he&#39;s young and apparently he was up for the lead originally for this for this movie</p>
                            <p>Bryan White (24:02.436)
                            this is running up because like yeah like we&#39;re running up to weird science fucking aliens</p>
                            <p>Bryan White (24:11.376)
                            yep yeah he&#39;s the lead in mortuary isn&#39;t he or he&#39;s the bad guy</p>
                            <p>Bryan White (24:20.276)
                            no yeah um but yeah man that dude when he died with a bumper he was the e was the best he was the best but as we come to find out chere not into this scholarship the scholarship thing she is dead set on keeping him at home and her earliest she</p>
                            <p>Bryan White (24:50.196)
                            earliest overtures compared to where this movie ends up are pretty pretty chill she just goes ahead and calls him stupid to his face like this is a college is college is where smart people go and you&#39;re not smart which goddamn goddamn</p>
                            <p>Bryan White (25:14.316)
                            yeah she&#39;s a she&#39;s a pick up artist these days</p>
                            <p>Bryan White (25:19.556)
                            yeah but like a she&#39;s right on fire like we go into the next day it&#39;s birthday day and like she is really playing this like makiavellian plan to just keep him from leaving her because you know she is as we find out she&#39;s got problems with people with people leaving her</p>
                            <p>Bryan White (25:44.576)
                            the card right because she gives him a birthday card and she&#39;s like well good you know good luck you know with the scholarship and you now you&#39;re going to do great at college but as he&#39;s reading the card i love this little touch they put in where as he&#39;s reading it she&#39;s like like with little emotions she&#39;s mouthing the words as he reads it it&#39;s such a sweet touch</p>
                            <p>Bryan White (26:17.596)
                            yeah</p>
                            <p>Bryan White (26:33.116)
                            yeah</p>
                            <p>Bryan White (26:40.936)
                            yeah right i mean it&#39;s very much an exploitation version of of like a better movie with better actors because like this they can&#39;t be bothered there&#39;s no time for subtlety in nuance like thee they really just are like well she&#39;s crazy but but also what is the deal with all the christian decorations around the house like she&#39;s got like votive candles there&#39;s like ether eastern orthodox icons is like that really</p>
                            <p>Bryan White (27:10.676)
                            a grim picture of jesus is head or maybe it&#39;s like the head of john the baptist because he&#39;s like no body involved this all</p>
                            <p>Bryan White (27:21.596)
                            uh uh</p>
                            <p>Bryan White (27:26.256)
                            all right and then like a little later on like he goes up to the attic in the attic in the attic first of all looks like it&#39;s half of the house but it&#39;s also like covered in cobwebs it looks like the the haunted mansion at disney world what is happening in this house like has she never been thought to go up and maybe does a little but this whole house is so is so weird and there&#39;s candles everywhere and she&#39;s got like a fucking candelabra that she goes around with is so wonderful like it&#39;s almost like</p>
                            <p>Bryan White (27:58.976)
                            there is this movie is like one degree removed from like a v c andrews novel</p>
                            <p>Bryan White (28:07.676)
                            it&#39;s good god like that&#39;s what i kept thinking was like if the if the novel if the novelization of this doesn&#39;t come with like a little die cut cover where there&#39;s like a girl&#39;s face like within like a window or something i would be yeah i would be very disappointed but it looks like it&#39;s just a movie poster um so like apparently her plan to keep billy around is to murder the</p>
                            <p>Bryan White (28:36.616)
                            v repairman</p>
                            <p>Bryan White (28:43.276)
                            it&#39;s it&#39;s crazy how hard she comes on to him like she&#39;s like i need a man she&#39;s in this dress just got the flower in her hair and the guy very casually rebuffs are and this this does not this does not go over well like she she turns on him and stabs him to death in a way that just spatters blood everywhere like it&#39;s crazy because it&#39;s like it&#39;s all over her</p>
                            <p>Bryan White (29:12.916)
                            this pink dress is suddenly like brown um it</p>
                            <p>Bryan White (29:22.296)
                            it&#39;s crazy because like this movie comes out of nowhere in terms of asher&#39;s like career like everything else he had done is like sit comes and like beach party movies and then he&#39;s got this one that is like creepy and squirmy and makes you feel real bad while you&#39;re watching it</p>
                            <p>Bryan White (29:46.636)
                            oh like it&#39;s got to be it&#39;s just like it&#39;s just like the last one in amity ville where like he was probably just he knew somebody and he wasn&#39;t doing anything at the time and was like yeah sure i&#39;ll come in you know like or he was like getting audited like who knows who knows but like this movie is just a weird like like dark spot on the guys on the guy&#39;s resume it&#39;s wild um</p>
                            <p>Bryan White (30:16.816)
                            so so while she&#39;s doing this billy is like right outside and apparently her like her neighbors are as well that neighbor lady is hilarious mart margie margery she is the best so like like they&#39;re all kind of coming in sort of congregating in the house at the same time and they&#39;re there just in time</p>
                            <p>Bryan White (30:50.616)
                            it&#39;s it is oh it&#39;s yeah because this is like the first like real sort of like explosive scene of violence in the movie and then it cools for a really long time but i would say that as well this movie has pacing problems but it</p>
                            <p>Bryan White (31:11.196)
                            yeah but it it definitely like once it&#39;s like okay we&#39;re going into the end game like it totally makes up for it but this this this murder scene in particular is like really really brutal and it sort of sets the table for the rest of the movie as</p>
                            <p>Bryan White (31:29.416)
                            she sort of sets it up like this man tried to rake me you all saw it but of course nobody saw it like they all came in and the guy was just laying there dead and she is hysterical like she is out of her mind</p>
                            <p>Bryan White (31:48.616)
                            i&#39;ve got that as a note too like she is acting in an entirely different movie while they&#39;re making this because like everybody else is just like measured and like their their performance is very ordinary and she is just like the whole time like it it is so crazy but this is when this is when the police show up and these guys are the worst movie cops i&#39;ve ever seen and i have seen and i&#39;ve seen</p>
                            <p>Bryan White (32:18.356)
                            a lot of jallow cops these guys are so so much worse first of all apparently dead body hilarious these guys are laughing it up um</p>
                            <p>Bryan White (32:34.496)
                            yeah bleach from silent deadly night yep i love that i got a note o that as soon as he showed up i was like silent deadly night that&#39;s great</p>
                            <p>Bryan White (32:50.756)
                            oh god because yeah because he just kind of comes over and he&#39;s like so do you buy this whole rape thing he&#39;s like no</p>
                            <p>Bryan White (33:01.336)
                            but but like of of these cops like bost fencon&#39;s i can&#39;t even remember his name i just know him is both fencin but whatever that cop&#39;s name is he is the worst fucking cop ever i mean it sucks that his instincts are right but like what the fuck dude is everybody gay to this guy like</p>
                            <p>Bryan White (33:25.036)
                            and they are they re loose with the slur in this movie it is it is because there is there&#39;s really his</p>
                            <p>Bryan White (33:38.536)
                            yeah there&#39;s a really fucking great note where he basically he sits them he sits them down and has them sort of go over their story again um and he apparently apparently one of her claims was he touched her booby which i deposit and go back just to make sure that that&#39;s what he said and apparently that&#39;s what she told him</p>
                            <p>Bryan White (34:09.836)
                            oh god but like he is like he lays it on so thick and like the whole time it&#39;s like well i hope he gets killed because this guy is a fucking monster um we&#39;ll get to it eventually but when he does die oh it&#39;s so great</p>
                            <p>Bryan White (34:33.956)
                            holy shit</p>
                            <p>Bryan White (34:37.955)
                            oh good god yeah i know that was that was one thing that we kind of talked about before we even got into this was like the sort of comparison of those two those two performances because louise lasar&#39;s blood range character is also like equally as unhinged um i was expecting susan terrell at it just at any point to just like start smearing the walls with handfuls of turkey stuffing or something like that it&#39;s it&#39;s</p>
                            <p>Bryan White (35:07.995)
                            you can&#39;t leave me like this yeah yeah</p>
                            <p>Bryan White (35:14.696)
                            we&#39;re gonna have to do that one it&#39;s at a certain point because that one abuses the fun out of the joke hey this ain&#39;t this ain&#39;t it&#39;s not cranberry sauce like they do that like three times in the movie it&#39;s like all right you guys let&#39;s relax a little bit</p>
                            <p>Bryan White (35:29.916)
                            and they love it so much they put it on the poster um so yeah the the intensity like like the cops instinct is right that billy like i don&#39;t think now his instinct is that billy did the killing which he did but he is so like committed to this to this conclusion which you know i&#39;ve watched a lot of true crime ship and apparently i mean that&#39;s what the cops operate like</p>
                            <p>Bryan White (35:59.656)
                            they get an idea and that&#39;s just kind of how how it goes followed all the way down no matter what but like he is super super menacing because like eventually he comes back around just to just to be a real pain in the ass to billy and this is</p>
                            <p>Bryan White (36:22.376)
                            oh good god yeah like this is that is that is that is that is a characteristic like her personality that kind of keeps coming out and they never really say where it comes from though i would assume like with all of the sort of like religious iconography around the house it probably is sort of born from there but like everybody in this movie is like super super freaked out about gay people and this is when bostfenson comes back around</p>
                            <p>Bryan White (36:52.216)
                            he&#39;s not</p>
                            <p>Bryan White (36:57.696)
                            no because well okay well maybe because i think i think actually i do think that that sort of i&#39;m jumping a little bit ahead because this is the part where is like child molestation is up three per cent that i&#39;m like</p>
                            <p>Bryan White (37:15.536)
                            fuck you and he&#39;s</p>
                            <p>Bryan White (37:21.576)
                            there&#39;s a pamphlet on the wall next to him that just says rape in block letters it&#39;s like rat but not before ten per cent i&#39;m like holy ship what is happening in this community like don&#39;t go to arizona um but his office his office is also really fucked up like like behind him he&#39;s got like all of his sort of like like military badges and trophies and stuff like that and then this is when coach landers kind of comes</p>
                            <p>Bryan White (37:49.816)
                            i didn&#39;t apparently or like he&#39;s just got like a police dog that he hangs out with but i didn&#39;t see the dog when he walks into the frame and he sort of starts talking to this like whatever it is off camera i&#39;m like who the fuck is he talking to</p>
                            <p>Bryan White (38:05.216)
                            and yeah then then the camera pans over and there&#39;s a german shepard like chain to the floor next to his next to his desk but like opposite his desk beaus this is when coach landers comes in like he&#39;s gone sfensonh&#39;s gone to the school he&#39;s seen billy like playing basketball sort of interacting with landers and he sort of i think this is the part where he sort of goes up to landers and he produces the ring that kind of like that that&#39;s sort of like i know you&#39;re gay and this is your boy friend and why aren&#39;t you more upset about this</p>
                            <p>Bryan White (38:36.476)
                            it does not seem all that bumped like this like like his boo was just murdered</p>
                            <p>Bryan White (38:45.796)
                            i guess but he oh he says something like you might want to you might want to think about resigning or else you&#39;ll get lynched with you like fuck thislike this</p>
                            <p>Bryan White (38:59.416)
                            he like he really is going he&#39;s like really going for it like his personification of this character is like i really want everybody to just hate the shit out of me</p>
                            <p>Bryan White (39:14.916)
                            i</p>
                            <p>Bryan White (39:21.576)
                            i think you might be out of something</p>
                            <p>Bryan White (39:27.796)
                            i would i would say i would say that you&#39;re right but let&#39;s get back i&#39;m gonna go back just want to remark upon some of the decorations so no this is some of the decorations in his office there is a scene so when landers first comes in he steps into the office and as soon as he steps into frame the camera kind of pans with him and there is a bull whip attached to the wall and at first i&#39;m like wait a second like who&#39;s the degenerate here spens but and then like it sort of</p>
                            <p>Bryan White (39:57.556)
                            hands over and it turns out that he&#39;s just got like this kind of like a western motif going on</p>
                            <p>Bryan White (40:18.236)
                            oh that&#39;s right</p>
                            <p>Bryan White (40:22.436)
                            clearly yeah clearly something is up</p>
                            <p>Bryan White (40:26.756)
                            uh</p>
                            <p>Bryan White (40:30.256)
                            it is i just just like right before right before it sort of like revealed the broader sort of motif of the office i was like what is happening here like why is there a bull whip on the wall but then so we go back and we i know i mean it&#39;s just it&#39;s just decorating man i mean so the next the next day we was so like the whole time we&#39;re sort of dancing around it but billy&#39;s got a girl friend</p>
                            <p>Bryan White (41:01.656)
                            oh right yeah she because also she was like i had to look this up but she apparently is like significantly older than then than like jimmy mcnikaland she plays i mean she plays young but like after i read that i was like oh yeah i guess she does kind o oo like she&#39;s in her thirties um but she&#39;s like a photographer maybe just for fun maybe for the school but like right</p>
                            <p>Bryan White (41:36.016)
                            that&#39;s weird i suspect that&#39;s kind of like a southwest and also like california because i&#39;ve seen that in other movies where like where you know because you know it&#39;s fucking to england like we&#39;ve got we&#39;ve got like you know we we eat indoors but like i think they used to do that in like beverly hills nine o two n o and she like like california schools were little bit more loose they had outdoors sort of sort of dining but</p>
                            <p>Bryan White (42:04.036)
                            oh that&#39;s right yeah yeah also when i went there there was actually a smoking section which that&#39;s unheard of but as we&#39;re introduced to her actually i&#39;m going away back to the beginning so we see billy playing basketball and you don&#39;t really see what she&#39;s doing but like every time he does a sweet basketball move there&#39;s like a snap sound and the in the frame like stops it happens a few times before it&#39;s revealed that she&#39;s taking</p>
                            <p>Bryan White (42:33.816)
                            photos of him um and i thought i was having a stroke at first as this as the scene was playing out but</p>
                            <p>Bryan White (42:55.696)
                            yeah</p>
                            <p>Bryan White (42:58.436)
                            yeah</p>
                            <p>Bryan White (43:00.856)
                            yeah yeah because like i mean it&#39;s the sound effect wasn&#39;t really enough that like i had to watch it a second time to really sort of like really kind of feel a little bit more comfortable with it but the first time this was happening i was like like oh shit i think i&#39;m about to have a stroke but also he yeah i know i saw i should be on the lookout but he he also like has a really long meeting with coach landers and when she runs in</p>
                            <p>Bryan White (43:30.756)
                            billy again it&#39;s like six p m and like dark in the high school it&#39;s like what is he doing there still</p>
                            <p>Bryan White (43:46.096)
                            uh uh</p>
                            <p>Bryan White (43:51.396)
                            she&#39;s she&#39;s really in there just to be like okay everybody like look he&#39;s not really gay because because this becomes like a kind of a major running theme throughout the rest of it and even at a point where she&#39;s like she doesn&#39;t really say it but her implication as they&#39;re like in bed together is like like why don&#39;t we do this more like you&#39;re not gay are you</p>
                            <p>Bryan White (44:16.996)
                            uh uh</p>
                            <p>Bryan White (44:21.336)
                            all right easy but</p>
                            <p>Bryan White (44:28.376)
                            uh so so uh briley silent night deadly night he approaches her basically because he&#39;s got a whole alternative theory so if spenson&#39;s idea is that billy did did the murder his his alternative theory is billy didn&#39;t do the murder and sherold did the murder</p>
                            <p>Bryan White (44:50.956)
                            like but yeah she&#39;s telegraphing it like crazy throughout the whole thing but he approaches her like it&#39;s a fucking dark as she&#39;s like going into her house and he&#39;s wearing the fanciest neckerchief with like a big gold knot on it it was the weirdest detail and he&#39;s like oh he didn&#39;t mean to scare you but like he&#39;s also kind of ill suited to play a cop but he&#39;d be really really good to play like a hill billy who creeps and learn</p>
                            <p>Bryan White (45:20.896)
                            in the darkness with it that&#39;s a thing like this is like eighty two so it&#39;s still kind of feeling the hangover in the seventies and i feel like people wear a lot of neckerchiefs in the seventies but so this is like so again now spenson shows up as billy is like practicing basketball and this is when he throws i swear to god he must throw the bomb like three dozen times and every</p>
                            <p>Bryan White (45:50.776)
                            time he does like he really put some stack on it like every time he says it it is so nasty like it is it is and it&#39;s very clearly by design like i kind of got the eeling that the kind of like incestuous tones were kind of like maybe done on the day it was kind of like maybe was improvised because one of the things i read about the novelization is like that does not ever come into it and apparently like the the</p>
                            <p>Bryan White (46:17.376)
                            i don&#39;t know i don&#39;t think it&#39;s quite as thick as it was it amityavilbut because like in this like this period that we&#39;re talking about right now like this is that kind of like a lull in the middle um and aunt share is really kind of playing games but the she doesn&#39;t really hit it hard again until things start to really kind of like lurch toward the toward the conclusion</p>
                            <p>Bryan White (46:39.796)
                            but again she is out of her fucking mind and this is when we find we find out like she&#39;s got like a shrine to to chuck strang who we find out is billy&#39;s father and you know the big reveal that we kind of get to a little later on is she&#39;s actually his mother and the strang guy knocked her up and then bailed on her or so</p>
                            <p>Bryan White (47:10.336)
                            or so we think um and so she goes down and has to knock down the boards of this room in the basement that has like like a shrine to him which she addresses like she&#39;s speaking to the shrine as though he&#39;s there and she&#39;s addressing him while holding like this real hammer horror candelabra such an awesome</p>
                            <p>Bryan White (47:41.716)
                            right well i that&#39;s actually a really good question because she does sort of like show up with the candelabra and there&#39;s there&#39;s already there&#39;s already candles lit there right that&#39;s that&#39;s a really really weird detail um but like she&#39;s still playing games like the whole plot the whole reason that she killed this guy is because it would sort of like get billy kind of stuck up and jammed up in this kind of like legal situation</p>
                            <p>Bryan White (48:11.696)
                            that presumably she intends to just go on and on or something it&#39;s really not clear how this is supposed to kind of jam because</p>
                            <p>Bryan White (48:23.956)
                            it&#39;s not it&#39;s fully crazy but i&#39;ll tell you just the way that she behaves throughout this whole movie is like crazy like nothing else like nobody does crazy like she does like she is hysterical throughout the whole thing</p>
                            <p>Bryan White (48:43.956)
                            ah maybe</p>
                            <p>Bryan White (48:51.856)
                            oh okay yeah you had mentioned this and i was not able to track these down well i would imagine i would imagine that that&#39;s that&#39;s kind of like i mean that&#39;s probably what kind of drew her to one of like to war hall because like she was one of war halls</p>
                            <p>Bryan White (49:10.076)
                            she&#39;s she&#39;s in two of his movie she&#39;s in bad and she&#39;s in</p>
                            <p>Bryan White (49:16.676)
                            i think that&#39;s it&#39;s either that or it&#39;s trash i can&#39;t remember off the top of my head</p>
                            <p>Bryan White (49:22.816)
                            she&#39;s in a couple of his movies and like she definitely seems like a sort of like a weird ass personality that he would just sort of like gravitate to but like again like her character an angel is definitely not like off the hook like this and she seems to be kind of encouraged to do that sort of thing in cry baby though like that character is not like insane she&#39;s just like like a hill billy</p>
                            <p>Bryan White (49:48.916)
                            there was</p>
                            <p>Bryan White (49:52.976)
                            um so we get another moment where where margie the neighbor comes over and she comes over with a pie and and a stack of playboy magazines such a weird detail is such a strange are they like her husband&#39;s like like i want to know what the genesis of this plan was like maybe like i hope</p>
                            <p>Bryan White (50:22.716)
                            it was like a discussion scene that maybe just kept cut out or something because</p>
                            <p>Bryan White (50:29.476)
                            because she puts it down and the camera lingers long enough for you to go oh ship those are play boys and it never comes up like in conversation and this is kind of like and this is concerning that like because aunt charl is later very concerned with billy&#39;s morality so this seems like a really strange strange detail to just kind of let slide but i eventually become legit worried for this woman</p>
                            <p>Bryan White (50:57.276)
                            um because as things are re as we&#39;re sort of approaching this scene like this is where things start to kind of get out of hand because billy is still kind of like moving away in spite of all of all van cherell&#39;s maginations um and he has</p>
                            <p>Bryan White (51:19.196)
                            this is true like she because right because he&#39;s going out like the big game is coming up and he&#39;s got the scout that comes and so she sabatages his sort of designs on really kind of like moving this this scout and kind of getting the scholarship by drugging his milk and in the middle of the game it really hit so many collapses and she she brings him back home and apparently he&#39;s been sleeping for a really long time and he wakes up and he&#39;s like oh shit i got to go to school and she&#39;s like</p>
                            <p>Bryan White (51:48.956)
                            what do you need to go to school for</p>
                            <p>Bryan White (51:52.856)
                            she says</p>
                            <p>Bryan White (52:00.056)
                            and then she says i&#39;ll teach you things in that like in that weird because she&#39;s got like two modes in this movie where she&#39;s completely out of control or she has that weird sort of like demure little girl kind of quality</p>
                            <p>Bryan White (52:19.076)
                            this is true</p>
                            <p>Bryan White (52:41.456)
                            this is like yeah but like the ease that she sort of slides between the two is just real wild because like this also sort of this this sort of precedes a scene where billy and julie i think is her name they like they finally get around like having sex and the sort of</p>
                            <p>Bryan White (53:03.076)
                            like the idea the question of his sexuality comes up but then aunt share</p>
                            <p>Bryan White (53:16.776)
                            i didn&#39;t get that impression at all actually because right before this all goes down like he invites her over and he&#39;s like a yeah they&#39;re all going to sadona um and she&#39;s and she gets this look like oh finally</p>
                            <p>Bryan White (53:31.716)
                            yeah but then like but the whole time there&#39;s also that sort of like first person perspective like jallow killer scene where like somebody&#39;s coming up on the house and it&#39;s like she had like i wonder who this is and now and then immediately jere burst through the door she&#39;s like dressed and you get out of here you that and and then we cut to him like in the shower bear ass naked and she just burges in to confront</p>
                            <p>Bryan White (54:01.536)
                            m</p>
                            <p>Bryan White (54:05.456)
                            yeah</p>
                            <p>Bryan White (54:11.476)
                            yeah right right so he goes from here he kind of starts to go like snooping like something is up but he finds his box</p>
                            <p>Bryan White (54:26.136)
                            oh that&#39;s right and it&#39;s like it&#39;s finally like she cleans up the hunted mansion attic but it&#39;s like full of like baby toys</p>
                            <p>Bryan White (54:36.416)
                            i was going to say it does i mean it definitely doesn&#39;t look like it did before because before like everything is covered in like i suppose but yeah he definitely the weird quality of this is the decoration of this attic because all he&#39;s surrounded by like baby toys no no they&#39;re it&#39;s all a little old um it&#39;s a really it&#39;s a really great a really great like production design like little</p>
                            <p>Bryan White (55:05.316)
                            detail that they threw in because like i definitely notice that and i&#39;m like what is going on in this room but like it really does kind of like dive with the rest of the theme where like she&#39;s definitely like into him but also wants to keep him there as like her little boy so he he starts to like look around he goes into a room he finds this very ornate box that&#39;s full of like correspondences where he finds i believe a birth certificate</p>
                            <p>Bryan White (55:35.476)
                            um and like some letters from chuck strang and it&#39;s all it&#39;s all like essentially discovers that chess mother and that who the parents they thought were his parents were her sister and her sister&#39;s husband and they were just like taking care of her taking care of him because like she was a single woman you know quote quote in trouble um and but like she catches him and sort of chases him off before</p>
                            <p>Bryan White (56:05.336)
                            and l s able to really sort of get the deal so he puts up this plan where julie is going to go into the house got i hope that&#39;s her name but i know i&#39;m gonna look it up but she&#39;s supposed to go in and like distract aunt charl while he goes and gets a good gets a good look at the at that stuff in the box and he essentially yeah her name is her name is julie</p>
                            <p>Bryan White (56:35.276)
                            thank god um and essentially this is like you think at first but it looks like it&#39;s a death sentence for julie because</p>
                            <p>Bryan White (57:15.396)
                            yeah right because like it does it does it sort of hits this stasis point very early on where like once the t v repair man is killed it suddenly just coasts for like a really long time like it&#39;s killing time like it really kind of like hits you over the head a bunch of times about like always trying to go to college and he&#39;s trying to pull away from this woman</p>
                            <p>Bryan White (57:44.696)
                            yea that it boss fence just being awful um but this is the this is the moment where the movie just kind of like switches gear and just goes crazy so like she at one point julie is distracted she&#39;s like looking in the refrigerator or something like that and she hits her over the head with like a meat tenderizer which she has been pumbling like a stake flat like this is like an ordinary cut of steak that she she manages to sort of like double in length from just like nervously smashing</p>
                            <p>Bryan White (58:14.496)
                            thing to pieces and she turns it on julia julia is presumably killed because there is blood all again all over the place from this thing and this is where this is where margie comes back in where she&#39;s or maybe she&#39;s been here like she&#39;s been hanging around where she feigns that she leaves at one point because she&#39;s very clearly tipped off to something going on um and this is what i&#39;m like i really i&#39;m really worried about this lady and it turns out that my worries are</p>
                            <p>Bryan White (58:45.236)
                            are are not for nothing because aunt charl suddenly goes like full pamela varies where there&#39;s a machete that&#39;s been in play this whole time like a marjorie gets like gets</p>
                            <p>Bryan White (59:07.356)
                            oh my god like they really beat like the really beat on that body yeah so like my note was you got your pot boiler in my slasher movie you got your slasher movie in my pot boiler to two great tastes to taste great together because like this is definitely like it goes to say like it it&#39;s it really is like a weird v c andrews novel made a movie but for the first two thirds of it and then all of a sudden it is in all out slasher movie where it&#39;s like it&#39;s dark and it&#39;s storming and</p>
                            <p>Bryan White (59:37.576)
                            uh</p>
                            <p>Bryan White (59:41.636)
                            almost because like the finale here this this the way this movie ends is way out of touch with the rest of the movie like the rest of the movie is very it simmers and like like you said like you know something bad is going to happen but the way that things bad like all the bad stuff happens is not like the rest of the movie it&#39;s really really wild it&#39;s almost like it&#39;s almost like producer notes came in and we&#39;re like let&#39;s make this a little bit more like friday the thirteenth the kids really like friday the thirteenth so like they equip er with with a machete</p>
                            <p>Bryan White (01:00:11.836)
                            brittle shows up and gets his hand shut chopped off when he goes to turn on goes to turn on a light like all of a sudden the body count just climbs substantially because like for most of the movie like one person is dead the whole time this is true like this is not a long like conclusion or drawn out but like julie it turns out it&#39;s not dead she&#39;s just been like knocked unconscious um margie has been sort of like sneaking around the house and she finds julie</p>
                            <p>Bryan White (01:00:41.556)
                            stuff like in a closet and becomes like really kind of suspicious and this is what ultimately leads to her death but julie gets up and she manages to just dodge death for the rest of the movie</p>
                            <p>Bryan White (01:01:00.636)
                            yeah yeah what is oh yeah she&#39;s like herald does that thing where she like she throws jewelie and then she kind of like goes like cross body block where like she&#39;s almost like she&#39;s jumping off the top rope</p>
                            <p>Bryan White (01:01:20.776)
                            okay hit me</p>
                            <p>Bryan White (01:01:40.436)
                            oh no she hadn&#39;t</p>
                            <p>Bryan White (01:02:15.256)
                            she&#39;s nuts</p>
                            <p>Bryan White (01:02:31.676)
                            oh man but yeah like like as we&#39;re sort of ramping up to this like she has like fully dumped all of the drugs into the milk and like forced billy to drink it so he&#39;s like out of his fucking mind the whole time that this is happening and this is the part where she takes pours it into his mouth and then she like licks his entire hand</p>
                            <p>Bryan White (01:02:54.576)
                            i&#39;m your girl friend now no</p>
                            <p>Bryan White (01:03:01.396)
                            oh h</p>
                            <p>Bryan White (01:03:08.896)
                            i got it like i got to wonder like what what&#39;s worse is is it is it that that scene because that&#39;s another one of those ones where she&#39;s just like frantic and crazy like she&#39;s cut her hair or quoth cut of hair basically she ditched the wig but like it&#39;s set up like she caught her own hair and it looks nuts like it&#39;s completely insane like a crazy person just like manically decided to chop all their hair off in this scene um is it that scene or is it the scene where</p>
                            <p>Bryan White (01:03:38.756)
                            one and tris where he&#39;s first like what do you take off your nightgown</p>
                            <p>Bryan White (01:03:48.256)
                            i suppose</p>
                            <p>Bryan White (01:03:59.236)
                            i would i okay i&#39;ll grant you that because this is at this point this is like fever patch like we are right</p>
                            <p>Bryan White (01:04:12.416)
                            that&#39;s true and then yeah and this is the oitwhehe gets the nifeandhe stabs her and this is a really crazy kill scene because it&#39;s not like it&#39;s not done in slow motion it&#39;s done very quickly and and it&#39;s set up in such a way that it looks like the knife slowly slides into her into her chest and like she she goes over dead but like the way that that yeah of course i mean we&#39;re in slasher movie mode so she&#39;s got to have the big come back but that that stab scene</p>
                            <p>Bryan White (01:04:42.396)
                            so awful because it&#39;s not like where he just goes wam and hits it real fast and like you know the knife cole collapse into the handle or something like that like it looks like he is slowly stabbing this woman</p>
                            <p>Bryan White (01:05:06.356)
                            this part it is a it is a bummer</p>
                            <p>Bryan White (01:05:14.036)
                            no because like this is the thing like and to is to mc nichol&#39;s credit like he really he really sells it like this is a very strong moment of tragedy for him because like this is the woman who you know</p>
                            <p>Bryan White (01:05:32.016)
                            that&#39;s terrible i mean that&#39;s the good gun that&#39;s already happened and as far as i&#39;m concerned he&#39;s helping himself out here by doing this but like like he really does sort of sell it because it is like a tragic sad moment because like it&#39;s like the beginning where like blood is just getting on everything he&#39;s got it all over him it&#39;s all over her clothes and like he is like really really making you believe that like this sucks like nothing else but of course we&#39;re in slasher movie mode and so she comes back and she uses she</p>
                            <p>Bryan White (01:06:01.736)
                            pulls the knife out and slashes the shit out of him yeah oh god yeah again because it looks fuck like l of a sudden i was like man that that knife was sharp i guess because like he&#39;s got like a big cut across his back and like i was chased and ship like that um but she gets killed for good and the police show up and bos fenson is there and he&#39;s got he basically</p>
                            <p>Bryan White (01:06:33.056)
                            he is because he basically he basically clears the room like tells all the cops to get the fuck out and he&#39;s got his gun on billy um and it looks like he&#39;s gonna just fucking shoot him like like what the fuck like why because there&#39;s he&#39;s given you really i mean apart from the fact that he&#39;s just fucking awful throughout the whole movie just a terrible like the worst kind of sucking homophobe he doesn&#39;t really give you an indication that he&#39;s like murderous</p>
                            <p>Bryan White (01:07:02.496)
                            ther&#39;s like no reason that he would actually want to kill him</p>
                            <p>Bryan White (01:07:10.416)
                            yeah</p>
                            <p>Bryan White (01:07:18.396)
                            it does but like he&#39;s disarmed and billy gets the gun and he holds it on him for a really really long time before he shoots him and it is such a satisfying payoff the scene like doesn&#39;t really make any sense but like just watching him die after a while after seeing this guy just be a terrible terrible creature and the whole the whole time so satisfying so wonderful</p>
                            <p>Bryan White (01:07:45.256)
                            yeah right like the everybody else kind of floods in and like right and julie&#39;s like i need to like i need to go to him and the cops like yeah why not and she does and then they sort of have this thing and they are all like covered in blood and like everybody s what it&#39;s like it&#39;s such a nasty thing they all look like they have just been fucking through it and then and then there&#39;s this and then there&#39;s this epilogue crawl that goes over and it was basically like hey don&#39;t worry about it he was acquitted</p>
                            <p>Bryan White (01:08:15.016)
                            all went to college together and i&#39;m like fuck that movie just totally did not need that it woud have been so much better if they just cut that</p>
                            <p>Bryan White (01:08:24.196)
                            basically yeah like and just the fact that it happens at all was such was such a bomber like i just did not care for that part at all i think</p>
                            <p>Bryan White (01:08:37.976)
                            it was so great yeah but yeah so that&#39;s but your maker nightmare maker also known as night warning yeah yeah so final thoughts you know and we&#39;ve kind of gone over and like i feel like it is a really it&#39;s a little slow going until the third act when it just goes crazy and drops the hammer everybody is</p>
                            <p>Bryan White (01:09:07.736)
                            acting their hearts out susan terrell is the best like i cannot i mean i feel like we there&#39;s just no way not to put too fine a point on it like there is just no way to sort of like oversell this like she is so good and like it&#39;s a it&#39;s a i think personally i think it&#39;s a good movie</p>
                            <p>Bryan White (01:09:31.876)
                            that&#39;s that&#39;s i think that&#39;s really what i mean to say and it</p>
                            <p>Bryan White (01:09:37.156)
                            definitely this is a movie that i want to show other people i feel like it is an experience that everybody that everybody&#39;s got to have especially if you feel like horror movies because it is really a different kind of horror movie in spite of the fact that it&#39;s just like liberally stealing from like other other movies like this is you know it&#39;s whatever happened to baby jane it&#39;s friday the thirteenth part one</p>
                            <p>Bryan White (01:10:02.976)
                            eh</p>
                            <p>Bryan White (01:10:14.336)
                            it is you know now that you say that the way just the locations that it&#39;s that it&#39;s shot in have you ever watched any of hose larazes movies have you have you seen the edge of the ax okay so i did not care for edge of the axe at all but it looks like it&#39;s sort of like the same it has that kind of weird like it&#39;s supposed to be somewhere else but it&#39;s very clearly california</p>
                            <p>Bryan White (01:10:44.376)
                            it&#39;s yeah there&#39;s just there&#39;s something about it the fact that that ash air came primarily from television it</p>
                            <p>Bryan White (01:10:56.856)
                            yeah</p>
                            <p>Bryan White (01:11:06.376)
                            yeah yeah and i mean it&#39;s one of those things where like the inexperience kind of works to its advantage because like it definitely does not feel like other horror movies probably due to the fact people who made it just don&#39;t make horror movies but it&#39;s a really great you know it&#39;s a really great effort it works for the most part there&#39;s definitely a lot of clunk bits that like we we&#39;ve sort of hit on that just don&#39;t really work but when it is working it&#39;s like it&#39;s just going so well and a lot of that has to do with susan terrell</p>
                            <p>Bryan White (01:11:36.196)
                            just like going just flooring at the whole time</p>
                            <p>Bryan White (01:11:46.756)
                            yeah when it is when it&#39;s when it is unpleasant it is seriously unpleasant i did i enjoyed the hell i&#39;m definitely going to show show this to people i am like this was the first time i had ever seen it also like this is one that like yeah it has been on my radar for a really long time but like i don&#39;t know there&#39;s there was nothing really terribly compelling about it from the outside which is why i think it&#39;s been sort of</p>
                            <p>Bryan White (01:12:15.836)
                            a hidden gem for so long like i really</p>
                            <p>Bryan White (01:12:27.176)
                            yeah yeah</p>
                            <p>Bryan White (01:12:38.096)
                            yeah</p>
                            <p>Bryan White (01:12:47.416)
                            i think the name definitely is one of the reasons why i&#39;ve stayed away from it for so long because it really it truly is a dumb fucking name it takes a million years to say it out loud it&#39;s a fucking mouthful it never it does not play into this story at all like it gives you no indication of what the movie is about there is nobody and there are no nightmare makers in this movie it&#39;s just</p>
                            <p>Bryan White (01:13:16.976)
                            no all right i guess we&#39;ll go with night warning but so what do you ant to do next</p>
                            <p>Bryan White (01:13:28.116)
                            let&#39;s do pieces one hundred percent i have no objections to that because let me tell you something about pieces i have never seen all of pieces i have watched it i have watched it ham in pieces but like i&#39;ve never seen the whole thing front back yeh</p>
                            <p>Bryan White (01:13:48.876)
                            all right all right so that&#39;s that&#39;s that&#39;s the brothers grim take on butcher maker nightmare maker we&#39;ll see in two weeks with with our thoughts on pieces all right see it</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/pieces">Next Episode</Link></li>
                        <li><Link to="/episodes/amityville-2-the-possession">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)